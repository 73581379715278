import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Button, Typography, Modal, CircularProgress, Fade, Slide } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion'; // Add framer-motion for smooth animations


// Email and phone validation regex patterns
const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//const phonePattern = /^[0-9]{10,14}$/; // 10 to 14 digits allowed'
const phonePattern = /^[+]?[\d\s\-()]{10,14}$/; // Allows for optional "+" and special characters

const DynamicForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    title: '',
    message: '',
    name: '',
    contactPreference: 'email',  // Default preference
    email: '',
    phone: '',
  });

  const [thankYouOpen, setThankYouOpen] = useState(false); // New state for Thank You modal
  const navigate = useNavigate(); // Use navigate to redirect

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
  };
  

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // To handle submission state
  // Step-specific help texts
  const step1HelpText = "Vad gäller ärendet ...";
  const step2HelpText = "Här kan du skriva ditt meddelande";
  const step3HelpText = "Skriv ditt namn här";
  const step4HelpText = "Välj kontaktmetod";
  const step5PhoneHelpText = "Telefonnummer (10-14 siffror)";
  const step5EmailHelpText = "Ange din e-postadress";
  const [open, setOpen] = useState(false); // For modal

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // Validation function
  const validate = () => {
    const newErrors = {};

    if (step === 1 && !formData.title.trim()) {
      newErrors.title = "Rubrik är obligatoriskt";
    }

    if (step === 2 && !formData.message.trim()) {
      newErrors.message = "Meddelande är obligatoriskt";
    }

    if (step === 3 && !formData.name.trim()) {
      newErrors.name = "Namn är obligatoriskt";
    }

    if (step === 5) {
      if (formData.contactPreference === 'email' && !emailPattern.test(formData.email)) {
        newErrors.email = "Ange en giltig e-postadress";
      }

      if (formData.contactPreference === 'phone' && !phonePattern.test(formData.phone)) {
        newErrors.phone = "Ange ett giltigt telefonnummer (10-14 siffror)";
      }
    }

    return newErrors;
  };

  // Handle form data change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Handle moving to the next step
  const handleNextStep = () => {
    const newErrors = validate();

    // Only proceed if there are no validation errors for the current step
    if (Object.keys(newErrors).length === 0) {
      setErrors({});
      setStep(step + 1);
    } else {
      setErrors(newErrors);
    }
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch('./contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Different handling based on contact preference
      if (formData.contactPreference === 'phone') {
        setThankYouOpen(true);
        setTimeout(() => {
          setThankYouOpen(false);
          window.location.href = 'https://westify.se';
        }, 4899);
      } else {
        // For email, just redirect without alert
        window.location.href = 'https://westify.se';
      }
    } catch (error) {
      console.error('Submission error:', error);
      alert('Ett fel uppstod. Vänligen försök igen.');
      setStep(1);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Styles for components
  const formStyles = {
    container: {
      maxWidth: '600px',
      margin: '0 auto',
      padding: '20px',
      position: 'relative',
    },
    stepIndicator: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '2rem',
      gap: '8px',
    },
    stepDot: {
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: '#ccc',
      transition: 'all 0.3s ease',
    },
    activeStepDot: {
      backgroundColor: '#FA660F',
      transform: 'scale(1.2)',
    },
    textField: {
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: '#FF842C',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#FA660F',
        },
      },
    },
    button: {
      background: 'linear-gradient(45deg, #000000 30%, #333333 90%)',
      color: 'white',
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer',
      transition: 'transform 0.2s ease',
      '&:hover': {
        transform: 'scale(1.05)',
        background: 'linear-gradient(45deg, #FA660F 30%, #FF842C 90%)',
      },
    },
  };

  // Animation variants for form steps
  const pageVariants = {
    initial: {
      opacity: 0,
      x: '-100vw',
    },
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: '100vw',
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5,
  };

  // Progress indicator component
  const StepIndicator = () => (
    <div style={formStyles.stepIndicator}>
      {[1, 2, 3, 4, 5].map((index) => (
        <motion.div
          key={index}
          style={{
            ...formStyles.stepDot,
            ...(step >= index ? formStyles.activeStepDot : {}),
          }}
          whileHover={{ scale: 1.1 }}
        />
      ))}
    </div>
  );

  // Enhanced form fields with better styling and animations
  const renderTextField = (props) => (
    <TextField
      {...props}
      sx={formStyles.textField}
      variant="outlined"
      fullWidth
      margin="normal"
    />
  );

  return (
    <div style={formStyles.container}>
      <StepIndicator />
      
      {/* Animated help text */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-easing text-black mb-4"
      >
        {step === 1 && step1HelpText.split('').map((letter, index) => (
          <span key={index} style={{ animationDelay: `${index * 0.03}s` }}>
            {letter}&nbsp;
          </span>
        ))}
        {step === 2 && step2HelpText.split('').map((letter, index) => (
          <span key={index} style={{ animationDelay: `${index * 0.03}s` }}>
            {letter}&nbsp;
          </span>
        ))}
        {step === 3 && step3HelpText.split('').map((letter, index) => (
          <span key={index} style={{ animationDelay: `${index * 0.03}s` }}>
            {letter}&nbsp;
          </span>
        ))}
        {step === 4 && step4HelpText.split('').map((letter, index) => (
          <span key={index} style={{ animationDelay: `${index * 0.03}s` }}>
            {letter}&nbsp;
          </span>
        ))}
        {step === 5 && formData.contactPreference === 'phone' && step5PhoneHelpText.split('').map((letter, index) => (
          <span key={index} style={{ animationDelay: `${index * 0.03}s` }}>
            {letter}&nbsp;
          </span>
        ))}
        {step === 5 && formData.contactPreference === 'email' && step5EmailHelpText.split('').map((letter, index) => (
          <span key={index} style={{ animationDelay: `${index * 0.03}s` }}>
            {letter}&nbsp;
          </span>
        ))}
      </motion.div>

      <form onSubmit={(e) => {
        e.preventDefault();
        if (step === 6) {
          handleSubmit(e);
        } else {
          handleNextStep();
        }
      }}>
        {step === 1 && (
          renderTextField({
            label: "Rubrik",
            name: "title",
            value: formData.title,
            onChange: handleChange,
            error: !!errors.title,
            helperText: errors.title,
          })
        )}

        {step === 2 && (
          renderTextField({
            label: "Meddelande",
            name: "message",
            value: formData.message,
            onChange: handleChange,
            multiline: true,
            rows: 4,
            error: !!errors.message,
            helperText: errors.message,
          })
        )}

        {step === 3 && (
          renderTextField({
            label: "Namn",
            name: "name",
            value: formData.name,
            onChange: handleChange,
            error: !!errors.name,
            helperText: errors.name,
          })
        )}

        {step === 4 && (
          <div>
            <label className='shadow ml-14'>Kontaktmetod:</label>
            <div className='ml-8'>
              <input
                type="radio"
                name="contactPreference"
                value="email"
                checked={formData.contactPreference === 'email'}
                onChange={handleChange}
              />
              <label className='mr-4'>E-post</label>
              <input
                type="radio"
                name="contactPreference"
                value="phone"
                checked={formData.contactPreference === 'phone'}
                onChange={handleChange}
              />
              <label>Telefon</label>
            </div>
          </div>
        )}

        {step === 5 && formData.contactPreference === 'phone' && (
          renderTextField({
            label: "Telefonnummer:",
            type: "tel",
            name: "phone",
            value: formData.phone,
            onChange: handleChange,
            error: !!errors.phone,
            helperText: errors.phone,
          })
        )}

        {step === 5 && formData.contactPreference === 'email' && (
          renderTextField({
            label: "E-post:",
            type: "email",
            name: "email",
            value: formData.email,
            onChange: handleChange,
            error: !!errors.email,
            helperText: errors.email,
          })
        )}

        <div className="button-container">
          {step > 1 && (
            <Button
              onClick={handlePrevStep}
              sx={{ mr: 2 }}
              variant="outlined"
              color="primary"
            >
              Tillbaka
            </Button>
          )}
          {step < 6 && (
            <Button
              onClick={handleNextStep}
              variant="contained"
              sx={formStyles.button}
            >
              Nästa
            </Button>
          )}
          {step === 6 && (
            <Button
              onClick={handleOpen}
              variant="contained"
              sx={formStyles.button}
            >
              Granska
            </Button>
          )}
        </div>
      </form>

      {/* Enhanced Modal with animations */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={{
            ...style,
            transform: 'translate(-50%, -50%) scale(1)',
            transition: 'transform 0.3s ease-out',
          }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Granska dina uppgifter
            </Typography>
            <TextField
              label="Rubrik"
              name="title"
              value={formData.title}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              id="outlined-textarea"
              multiline
              variant="outlined"
              label="Meddelande"
              name="message"
              minRows={4}
              value={formData.message}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Namn"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            {formData.contactPreference === 'email' && (
              <TextField
                label="E-post"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            )}
            {formData.contactPreference === 'phone' && (
              <TextField
                label="Telefonnummer"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            )}
            <div className="modal-actions">
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                sx={formStyles.button}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Bekräfta och skicka'
                )}
              </Button>
              <Button onClick={handleClose} sx={{ ml: 2 }}>
                Tillbaka
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* Thank You Modal with enhanced animation */}
      <Modal
        open={thankYouOpen}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={thankYouOpen}>
          <Box sx={{
            ...style,
            transform: 'translate(-50%, -50%) scale(1)',
            transition: 'transform 0.3s ease-out',
          }}>
            <Typography variant="h6" component="h2">
              Tack för att du kontaktade oss!
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Vi kommer att kontakta dig inom kort.
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default DynamicForm;
